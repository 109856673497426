// extracted by mini-css-extract-plugin
export var backgroundImage = "career-module--background-image--67809";
export var careerText = "career-module--career-text--a2420";
export var careerText1 = "career-module--career-text-1--e7f81";
export var careerText2 = "career-module--career-text-2--3ca7d";
export var container = "career-module--container--19e4e";
export var mobileBackgroundImage = "career-module--mobile-background-image--f1395";
export var mobileCareerText = "career-module--mobile-career-text--d4e0d";
export var mobileCareerText1 = "career-module--mobile-career-text-1--c0b89";
export var mobileCareerText2 = "career-module--mobile-career-text-2--766a5";
export var mobileContainer = "career-module--mobile-container--069b7";
export var mobileTextContainer = "career-module--mobile-text-container--26f64";
export var textContainer = "career-module--text-container--d6744";