// extracted by mini-css-extract-plugin
export var button = "team-card-module--button--51bc1";
export var cardMain = "team-card-module--cardMain--b4fd9";
export var contentDiv = "team-card-module--contentDiv--f7b56";
export var desc = "team-card-module--desc--a9fca";
export var descDiv = "team-card-module--descDiv--c4d40";
export var disabledDesc = "team-card-module--disabled-desc--3a425";
export var disabledSubtitle = "team-card-module--disabled-subtitle--d75cf";
export var imageDiv = "team-card-module--imageDiv--cf596";
export var selected = "team-card-module--selected--7862f";
export var selectedButton = "team-card-module--selectedButton--92c69";
export var subtitle = "team-card-module--subtitle--22596";
export var teamMain = "team-card-module--teamMain--d0b2b";
export var unselectedImageDiv = "team-card-module--unselected-imageDiv--05e78";