import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import * as styles from '../../style/career/career.module.scss';

const CareerHeader = ({ intl }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "careers/careers_header.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mobile: file(
          relativePath: { eq: "careers/careers_header_mobile.png" }
        ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const imageData = data.desktop.childImageSharp.fluid;
  const imageDataMobile = data.mobile.childImageSharp.fluid;
  const breakpoints = useBreakpoint();

  const renderDesktopView = () => (
    <div>
      <BackgroundImage
        Tag='section'
        fluid={imageData}
        className={styles.backgroundImage}
      >
        <div className={styles.container}>
          <div className={styles.textContainer}>
            <span className={styles.careerText1}>
              {intl.formatMessage({ id: `hr.bannerTitle1` })}
            </span>
            <span className={styles.careerText2}>
              {' '}
              {intl.formatMessage({ id: `hr.bannerTitle2` })}
            </span>
          </div>
        </div>
      </BackgroundImage>
    </div>
  );

  const renderMobileView = () => (
    <div>
      <BackgroundImage
        Tag='section'
        fixed={imageDataMobile}
        className={styles.mobileBackgroundImage}
      >
        <div className={styles.mobileContainer}>
          <div className={styles.mobileTextContainer}>
            <p className={styles.mobileCareerText1}>
              {intl.formatMessage({ id: `hr.bannerTitle1` })}
              <span className={styles.mobileCareerText2}>
                {intl.formatMessage({ id: `hr.bannerTitle2` })}
              </span>
            </p>
          </div>
        </div>
      </BackgroundImage>
    </div>
  );

  return <div>{breakpoints.md ? renderMobileView() : renderDesktopView()}</div>;
};

CareerHeader.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};


const StyledCareerHeader = styled(CareerHeader)`
  width: 100%;
  height: auto;
  background-size: cover;
`;

export default StyledCareerHeader;
