import PropTypes from 'prop-types';
import React from 'react';
import JobLogo from '../../images/job-icon.svg';
import LocationLogo from '../../images/location.png';
import Text from '../../lib/NewText/Text';
import * as styles from '../../style/components/job-card.module.scss';

const JobCard = ({ data }) => (
  <div
    style={{ display: 'flex', flexFlow: 'column' }}
    className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
    <div
      role="presentation"
      onClick={() => {
        if (data.hasJobDescription && data.id) {
          const url = `/job-detail/${data.id}`;
          window.open(url, '_blank');
        } else {
          window.open(data.link);
        }
      }}
      style={{ cursor: 'pointer' }}
      className={styles.cardMain}>
      <div className={styles.title}>
        <div className={styles.linkedin}>
          <img src={JobLogo} alt="job post" />
        </div>
        <div>
          <Text
            body
            style={{
              fontFamily: 'Montserrat SemiBold',
              color: '#3F5C83',
              fontSize: '1rem'
            }}>
            {data.title}
          </Text>
        </div>
      </div>
      <div className={styles.address}>
        <div className={styles.location}>
          <img src={LocationLogo} alt="address:" />
        </div>
        <Text style={{ color: '#727176', fontSize: '0.9rem' }}>
          {data.city && data.city.name}
          {data.city && data.city.name && ', '}
          {data.country && data.country.name}.
        </Text>
      </div>
      <div className={styles.desc}>
        <Text style={{ color: '#727176', fontSize: '0.8rem' }}>
          {data.description.slice(0, 200)}
          {data.description.length > 200 && '...'}
        </Text>
      </div>
    </div>
  </div>
);

JobCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    city: PropTypes.objectOf(PropTypes.string),
    country: PropTypes.objectOf(PropTypes.string),
    description: PropTypes.string,
    link: PropTypes.string,
    teamId: PropTypes.number,
    title: PropTypes.string,
    hasJobDescription: PropTypes.bool
  }).isRequired
};

export default JobCard;
