import React, { useEffect, useState } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import Text from '../../lib/NewText/Text';
import { fetchAllJobs, fetchAllTeams } from '../../services/career';
import * as styles from '../../style/components/career.module.scss';
import Header from './header';
import JobCard from './job-card';
import TeamCard from './team-card';

const CareerPage = ({ intl, language }) => {
  const breakpoints = useBreakpoint();

  const [teams, setTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [jobs, setJobs] = useState([]);

  const fetchTeams = async () => {
    const allTeams = await fetchAllTeams();
    setTeams(allTeams);
    setSelectedTeams([]);
    setJobs([]);
    return allTeams;
  };

  const fetchJobs = async () => {
    let allJobs = [];
    setJobs(allJobs);
    if (selectedTeams.length > 0) {
      allJobs = await fetchAllJobs(selectedTeams);
      setJobs(allJobs);
    }
  };

  const selectAllTeamsWithJobs = async (allTeams) => {
    let allJobs = [];
    const allTeamsIds = allTeams.map((s) => s.id);
    if (allTeamsIds.length > 0) {
      allJobs = await fetchAllJobs(allTeamsIds);
      const initialSelectedTeams = [];
      allTeams.forEach((team) => {
        if (allJobs.find((job) => job.teamId === team.id) !== undefined) {
          initialSelectedTeams.push(team.id);
        }
      });
      setSelectedTeams(initialSelectedTeams);
    }
  };

  useEffect(() => {
    fetchTeams().then((allTeams) => {
      selectAllTeamsWithJobs(allTeams);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeams]);

  const onTeamSelect = (id) => {
    let newSelectedTeams = [...selectedTeams];
    if (!newSelectedTeams.includes(id)) {
      // scroll only on "select" in small screens
      // if (breakpoints.md) {
      //   scrollTo('#jobs');
      // }
      newSelectedTeams.push(id);
    } else {
      newSelectedTeams = selectedTeams.filter((t) => t !== id);
    }
    setSelectedTeams(newSelectedTeams);
  };

  const jobCardsMobileView = (teamId) => {
    let cards = [];
    const filteredJobs = jobs.filter((j) => j.teamId === teamId);
    const isSelected = selectedTeams.includes(teamId);
    if (filteredJobs && filteredJobs.length > 0 && isSelected) {
      cards = filteredJobs.map((j) => <JobCard key={`job-${j.id}`} data={j} />);
    }
    if (filteredJobs && filteredJobs.length < 1 && isSelected) {
      return (
        <div>
          <Text body className={styles.semiTitle}>
            {isEmpty(selectedTeams)
              ? intl.formatMessage({ id: `hr.selectTeams` })
              : intl.formatMessage({ id: `hr.noJob` })}
          </Text>
        </div>
      );
    }
    return <div className={styles.cardsWrapper}>{cards}</div>;
  };

  const teamCards = () => {
    const cards = teams.map((t) => (
      <>
        <TeamCard
          key={`team-${t.id}`}
          onSelect={() => onTeamSelect(t.id)}
          selected={selectedTeams.includes(t.id)}
          data={t}
          intl={intl}
          language={language}
        />
        {breakpoints.md && jobCardsMobileView(t.id)}
      </>
    ));
    return <div className={styles.cardsWrapper}>{cards}</div>;
  };

  const jobCards = () => {
    const cards = jobs.map((j) => <JobCard key={`job-${j.id}`} data={j} />);
    return <div className={styles.cardsWrapper}>{cards}</div>;
  };

  return (
    <div className={styles.mainRow}>
      <Header intl={intl} />
      <div className={[styles.containerRow, 'container'].join(' ')}>
        <div>
          <Text title className={styles.title}>
            {intl.formatMessage({ id: `hr.teams` })}
          </Text>
          {teams && teams.length > 0 && teamCards()}
        </div>
        {teams && teams.length > 0 && (
          <div id="jobs" className="d-none d-md-block">
            <Text title className={styles.title}>
              {intl.formatMessage({ id: `hr.jobs` })}
            </Text>
            {jobs && jobs.length > 0 ? (
              jobCards()
            ) : (
              <div>
                <Text body className={styles.semiTitle}>
                  {isEmpty(selectedTeams)
                    ? intl.formatMessage({ id: `hr.selectTeams` })
                    : intl.formatMessage({ id: `hr.noJob` })}
                </Text>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

CareerPage.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  language: PropTypes.string
};

CareerPage.defaultProps = {
  language: 'en'
};

export default CareerPage;
