import { injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import * as React from 'react';
import { PageProps } from 'gatsby';
import CareerPage from '../components/career';
import Layout from '../components/ui/layout';
import DefaultLanguage from '../hoc/default-language';
import SEO from '../components/ui/seo';

const Career = ({ intl, location, pageContext }) => (
  <>
    <SEO page="career" language={pageContext.language} />
    <DefaultLanguage location={location}>
      <Layout location={location} language={pageContext.language} page="career">
        <CareerPage intl={intl} language={pageContext.language} />
      </Layout>
    </DefaultLanguage>
  </>
);

Career.propTypes = {
  pageContext: PropTypes.instanceOf(PageProps).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  intl: PropTypes.objectOf(PropTypes.object).isRequired
};

export default injectIntl(Career);
