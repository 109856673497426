import axios from 'axios';

const nodeApiURL = process.env.GATSBY_NODE_API;

const fetchAllTeams = async () => {
  const teams = await axios
    .get(`${nodeApiURL}/jobs/team`)
    .then((response) => response.data.teams)
    .catch((error) => {
      throw new Error(error);
    });
  return teams;
};

const fetchAllJobs = async (teams) => {
  const jobs = await axios
    .get(`${nodeApiURL}/jobs/jobs-in-team`, {
      params: { teamIds: [...teams] },
    })
    .then((response) => response.data.jobs)
    .catch((error) => {
      throw new Error(error);
    });
  return jobs;
};

export { fetchAllTeams, fetchAllJobs };
