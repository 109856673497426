import PropTypes from 'prop-types';
import React from 'react';
import Text from '../../lib/NewText/Text';
import * as styles from '../../style/components/team-card.module.scss';

const TeamCard = (props) => {
  const { onSelect, data, selected, language = 'en', intl } = props;

  return (
    <div className={[styles.teamMain, 'col-xs-10 ', selected ? styles.selected : ''].join(' ')}>
      <div className={styles.cardMain}>
        <div className={styles.contentDiv}>
          <div className={selected ? styles.imageDiv : styles.unselectedImageDiv}>
            <img src={data.image} alt={data.name} />
          </div>
          <div className={styles.descDiv}>
            <div>
              <Text subtitle className={selected ? styles.subtitle : styles.disabledSubtitle}>
                {language === 'en' ? data.name : data.nameES}
              </Text>
            </div>
            <div>
              <Text body className={selected ? styles.desc : styles.disabledDesc}>
                {language === 'en' ? data.descEN : data.descES}
              </Text>
            </div>
          </div>
          <div
            role="presentation"
            onClick={onSelect}
            className={selected ? styles.selectedButton : styles.button}>
            <Text body>
              {selected
                ? intl.formatMessage({ id: `hr.unselect` })
                : intl.formatMessage({ id: `hr.select` })}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

TeamCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    nameES: PropTypes.string,
    descEN: PropTypes.string,
    descES: PropTypes.string,
    image: PropTypes.string
  }).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  language: PropTypes.string
};

TeamCard.defaultProps = {
  selected: false,
  onSelect: () => {},
  language: 'en'
};

export default TeamCard;
